import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import i18next from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import he from "./translations/he";

i18next.use(initReactI18next).init({
  lng: "he",
  resources: {
    he,
  },
  fallbackLng: "he",
});

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>,
  document.getElementById("root")
);
