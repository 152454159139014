import { action, observable, makeObservable } from "mobx";
import { createContext } from "react";
import api from "../api/api";
import { Admin } from "../types";

class CommonStore {
  token: string | undefined = undefined;
  user: Admin | null = null;

  constructor(token: string, user: Admin | null) {
    makeObservable(this, {
      token: observable,
      user: observable,
    });

    this.token = token;
    this.user = user;

    this.setInitValues();
  }

  setInitValues = async () => {
    const token = localStorage.getItem("token");
    if (!token) return;

    //
    const user = await api("admins/me?expand=synagogues");
    if (user?.id) {
      this.user = user;
    }
  };

  setToken = (token: string) => {
    this.token = token;
  };

  setUser = (user: Admin | null) => {
    this.user = user;
  };
}

export default createContext(new CommonStore("", null));
